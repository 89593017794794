import { useEffect, useState } from 'react'
import { Layout, Formtitle } from '../components'
import { FormType } from '../utils/sharedTypes'
import { CircularProgress, Grid, Button } from '@mui/material'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { BsCheck } from 'react-icons/bs'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { MdRefresh } from 'react-icons/md'
import { PreRegistrationCustomProcess } from '../utils/sharedTypes'

type ValidationPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

const Signature = ({ form, setForm }: ValidationPropsType) => {
  const [isLoading, setIsLoading] = useState(true)
  const [docusigns, setDocusigns] = useState<{ prcp_id: number; url: string }[]>([])
  const navigate = useNavigate()
  const [selectedDocusignID, setSelectedDocusignID] = useState<number>()

  useEffect(() => {
    if (form.data.pre_registration_custom_processes.length !== 0) {
      window.addEventListener('message', (event) => {
        if (event.data === 'DOCUSIGN_SIGNED_REFRESH') {
          refreshPRCP().then().catch(console.error)
        }
      })
    }
  }, [form.data.pre_registration_custom_processes])

  const refreshPRCP = async () => {
    console.log('refreshPRCP')
    for await (const prcp of form.data.pre_registration_custom_processes) {
      const resp = await client.get(`/registration/pre_registration_custom_processes/${prcp.id}/`)
      if (resp.data) {
        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              pre_registration_custom_processes: prevState.data.pre_registration_custom_processes.map((p) => {
                if (p.id === prcp.id) {
                  return resp.data as PreRegistrationCustomProcess
                }
                return p
              }),
            },
          }
        })
      }
    }
    getDocusignURLS().catch((err) => {
      console.log('err', err)
    })
  }

  const getDocusignURLS = async () => {
    console.log('getDocusignURLS')
    for await (const prcp of form.data.pre_registration_custom_processes) {
      if (prcp.id) {
        const resp = await client.get(
          `/registration/pre_registration_custom_processes/${prcp.id}/get_or_create_docusign_envelope`,
        )
        if (resp.data) {
          setDocusigns((prevState) => {
            return [...prevState, { prcp_id: prcp.id, url: resp.data as string }]
          })
        }
      }
    }
  }

  const refreshDocusignURL = async (id: string) => {
    setIsLoading(true)
    const resp = await client.post(
      `/registration/pre_registration_custom_processes/${id}/refresh_docusign_envelope_url/`,
    )
    if (resp.data) {
      setDocusigns((prevState) => {
        return prevState.map((ds) => {
          if (ds.prcp_id.toString() === id) {
            return { ...ds, url: resp.data as string }
          }
          return ds
        })
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (form.data.pre_registration_custom_processes) {
      setIsLoading(true)
      getDocusignURLS()
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [form.data.id])

  useEffect(() => {
    if (!selectedDocusignID && docusigns[0]) {
      setSelectedDocusignID(docusigns[0].prcp_id)
    }
  }, [docusigns])

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Signature"
        subtitle={'Remplissez et signez le formulaire ci-dessous pour poursuivre votre inscription'}
      />
      <Grid
        sx={{ minHeight: { xs: 'calc(100vh - 260px)', sm: '100%' } }}
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {form.data.pre_registration_custom_processes.length > 1 && (
          <Grid
            container
            item
            spacing={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 4 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {form.data.pre_registration_custom_processes
                .sort((a, b) => a.id - b.id)
                .map((prcp) => {
                  return (
                    <Button
                      key={prcp.id}
                      sx={{ marginRight: '5px' }}
                      variant={selectedDocusignID === prcp.id ? 'contained' : 'outlined'}
                      onClick={() => {
                        setSelectedDocusignID(prcp.id)
                        refreshDocusignURL(prcp.id.toString())
                          .catch((err: Error) => {
                            Bugsnag.notify(err)
                            console.error(err)
                          })
                          .finally(() => {
                            setIsLoading(false)
                          })
                      }}
                    >
                      {prcp.sales_process.display_name || prcp.sales_process.name}
                      {prcp.validated && <BsCheck size="20px" style={{ color: 'inherit', marginLeft: '5px' }} />}
                    </Button>
                  )
                })}
            </div>
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={() => {
                refreshPRCP().catch((err) => {
                  console.error(err)
                })
              }}
            >
              <MdRefresh size="25px" />
            </Button>
          </Grid>
        )}
        <Grid
          sx={{
            minHeight: '100px',
            display: 'flex',
            flex: 1,
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <div
              style={{
                flex: 1,
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {docusigns.find((ds) => ds.prcp_id === selectedDocusignID)?.url && (
                <iframe
                  id={`${selectedDocusignID ?? ''}`}
                  key={selectedDocusignID}
                  src={docusigns.find((ds) => ds.prcp_id === selectedDocusignID)?.url}
                  width="100%"
                  height="100%"
                  title="DocuSign Embedded Signing"
                  style={{ top: 'unset', bottom: 'unset', border: 'none' }}
                />
              )}
            </div>
          )}
        </Grid>

        <Grid
          item
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              height: {
                xs: 45,
              },
            }}
            startIcon={<MdNavigateBefore />}
            onClick={() => {
              navigate({ pathname: './../pre-inscription/', search: location.search })
            }}
          >
            Précédent
          </Button>

          <Button
            variant="contained"
            sx={{
              height: {
                xs: 45,
              },
            }}
            disabled={form.data.pre_registration_custom_processes.some((p) => !p.validated)}
            endIcon={<MdNavigateNext />}
            onClick={() => {
              if (form.data.registration_type == 'SelfRegistration' && form.data.funding_organism?.code == 'DPC') {
                navigate({ pathname: './../recommandation', search: location.search })
              } else {
                navigate({ pathname: './../recapitulatif', search: location.search })
              }
            }}
          >
            Suivant
          </Button>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Signature
