import { useEffect, useState } from 'react'
import { Grid, useTheme, CircularProgress, Button } from '@mui/material'
import { ParamsType, PreRegistrationCustomProcess } from '../utils/sharedTypes'
import { BsCheck } from 'react-icons/bs'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'

const DocusignSuccess = () => {
  const theme = useTheme()
  const [params, setParams] = useState<ParamsType>({})
  const [isLoading, setIsLoading] = useState(true)
  const [preRegistration, setPreRegistration] = useState<PreRegistrationCustomProcess | null>()
  const [retry, setRetry] = useState(1000)

  useEffect(() => {
    const searchObj = window.location.search ? Object.fromEntries(new URLSearchParams(window.location.search)) : {}
    setParams(searchObj)
  }, [])

  const signDocusign = async (id: string) => {
    setIsLoading(true)
    const resp = await client.post(`/registration/pre_registration_custom_processes/${id}/sign_docusign/`)
    const prcp = resp.data as PreRegistrationCustomProcess
    if (prcp.validated) {
      setPreRegistration(resp.data as PreRegistrationCustomProcess)
      window.parent.postMessage('DOCUSIGN_SIGNED_REFRESH')
    } else if (retry < 20000) {
      setTimeout(() => {
        setRetry((prevState) => prevState + prevState)
      }, retry)
    } else {
      setPreRegistration(resp.data as PreRegistrationCustomProcess)
    }
  }

  useEffect(() => {
    if (params.prcp_id) {
      console.log('signDocusign', retry)
      signDocusign(params.prcp_id).catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
    }
  }, [params, retry])

  useEffect(() => {
    if (preRegistration) {
      setIsLoading(false)
    }
  }, [preRegistration])

  if (isLoading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Grid
          style={{
            flex: 1,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      {!preRegistration?.validated ? (
        <>
          <Grid
            item
            sx={{
              background: theme.palette.success.main,
              height: 80,
              width: 80,
              borderRadius: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <BsCheck size="40px" style={{ color: 'white' }} />
          </Grid>
          <div style={{ textAlign: 'center' }}>
            Un problème est survenu lors de la signature, veuillez cliquer ci-dessous.
          </div>
          <Button
            variant="contained"
            sx={{ marginTop: '5px' }}
            onClick={() => {
              if (params.prcp_id) {
                signDocusign(params.prcp_id).catch(console.error)
              }
            }}
          >
            Rafraichir
          </Button>
        </>
      ) : (
        <>
          <Grid
            item
            sx={{
              background: theme.palette.success.main,
              height: 80,
              width: 80,
              borderRadius: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <BsCheck size="40px" style={{ color: 'white' }} />
          </Grid>
          Contrat signé avec succès
        </>
      )}
    </Grid>
  )
}

export default DocusignSuccess
