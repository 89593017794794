import { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Typography, useTheme, useMediaQuery } from '@mui/material'

type StripeFormPropsType = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const StripeForm = ({ setOpen }: StripeFormPropsType) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const params = new URL(location.href).searchParams
  const global_id = params.get('global_id')
  const [submited, setSubmited] = useState(false)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmited(true)
    if (!stripe || !elements || !global_id) {
      console.log('!stripe || !elements || !global_id')
      throw Error('Stripe or elements undefined')
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/sante/stripe/?global_id=${global_id}`,
      },
    })

    if (error) {
      setSubmited(false)
      setErrorMessage(error?.message ?? '')
    }
  }

  useEffect(() => {
    const body = document.querySelector('body')
    const html = document.querySelector('html')
    if (body) {
      body.style.height = '100dvh'
      body.style.overflow = 'hidden'
      body.style.position = 'fixed'
      body.style.touchAction = 'none'
    }
    if (html) {
      html.style.height = '100dvh'
      html.style.overflow = 'hidden'
      html.style.position = 'fixed'
      html.style.touchAction = 'none'
    }
    // Clean up the styles when the component unmounts
    return () => {
      if (body) {
        body.style.height = ''
        body.style.overflow = ''
      }
      if (html) {
        html.style.height = ''
        html.style.overflow = ''
      }
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        justifyContent: 'flex-start',
        flexDirection: 'column',
        display: 'flex',
        paddingTop: '10px',
        paddingBottom: '10px',
        height: '100%',
        maxHeight: '100dvh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          flexGrow: 1,
          maxHeight: isSmall ? '80%' : '100%',
          overflow: 'hidden',
        }}
      >
        <Typography variant="h6" sx={{ mb: 5 }}>
          Enregistrer vos coordonnées bancaires
        </Typography>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <PaymentElement />
      </div>
      <div style={{ justifyContent: 'center', display: 'flex', paddingTop: 5 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false)
          }}
          sx={{ marginRight: '10px' }}
        >
          Annuler
        </Button>
        <Button variant="contained" type="submit" disabled={!stripe || submited}>
          Enregistrer
        </Button>
      </div>
    </form>
  )
}

export default StripeForm
