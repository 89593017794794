import {
  Grid,
  Typography,
  useTheme,
  Link,
  FormControlLabel,
  Checkbox,
  TextField,
  Modal,
  Popover,
  Divider,
  Button,
} from '@mui/material'
import { MdInfoOutline, MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { FormType } from '../utils/sharedTypes'
import { StartingDate, DropZone } from '.'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useState } from 'react'
import { DateField } from '@mui/x-date-pickers'
import './iframe.css'
import { useNavigate } from 'react-router-dom'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { isStepValid } from '../utils/misc'

type FifplPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const Fifpl = ({ form, setForm }: FifplPropsType) => {
  console.log('##### FIFPL #####', form)
  const navigate = useNavigate()
  const theme = useTheme()
  const [videoModal, setVideoModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleChange = (key: string, value: string | boolean | string[]) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      if (key === 'invoice_company_siret') {
        if (!value) {
          errors['invoice_company_siret'] = 'Veuillez renseigner votre SIRET'
        }

        if (!/^[0-9]{14}$/.test(value as string)) {
          errors['invoice_company_siret'] = 'Veuillez renseigner un SIRET correct'
        }
      }

      if (key === 'invoice_street') {
        if (!value) {
          errors['invoice_street'] = 'Veuillez renseigner votre adresse'
        }
      }

      if (key === 'invoice_city') {
        if (!value) {
          errors['invoice_city'] = 'Veuillez renseigner votre ville'
        }
      }

      if (key === 'invoice_postal_code') {
        if (!value) {
          errors['invoice_postal_code'] = 'Veuillez renseigner votre code postal'
        }

        if (!/^[0-9]{5}$/.test(value as string)) {
          errors['invoice_postal_code'] = 'Veuillez renseigner un code postal correct'
        }
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  const submitFinancement = () => {
    client
      .patch(`/registration/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../recapitulatif/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  return (
    <>
      <Grid container direction="column" spacing={4}>
        {/* DATE */}
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
              Date de début de votre formation
            </Typography>
            <Typography variant="body2">
              Vous disposerez de 6 semaines pour suivre votre formation. En cas de validation dans les temps impartis,
              nous vous offrirons un accès à vie à la formation !
            </Typography>
          </Grid>
        </Grid>

        <StartingDate form={form} setForm={setForm} />

        <Grid item>
          <Divider />
        </Grid>

        <Grid item container direction="column" spacing={1}>
          <Grid item container direction="column">
            <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
              Attestation URSSAF
            </Typography>
            <Typography variant="body2">
              Votre attestation de contribution à la formation professionnelle est disponible sur votre espace personnel
              du site de l'URSSAF. Pour la trouver,{' '}
              <Link href="https://info.walter-learning.com/guide/attestation-cfp-urssaf/" target="_blank">
                {' '}
                consultez notre guide en ligne
              </Link>{' '}
              ou{' '}
              <Link
                onClick={() => {
                  setVideoModal(true)
                }}
              >
                visionnez notre tutoriel vidéo
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: '100%', mt: 1 }}>
            <DropZone
              required
              maxFiles={1}
              maxSize={10}
              sx={{ minHeight: 100, background: theme.palette.background.default }}
              accept={{
                'image/*': ['.png', '.jpeg'],
                'application/pdf': ['.pdf'],
              }}
              bucketName="walter-inscription"
              folderPath={form.data.global_id}
              onUploaded={(url: string) => {
                handleChange('attestation_urssaf', url)
              }}
            />
          </Grid>
        </Grid>

        <Grid item>
          <Divider />
        </Grid>

        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
              Informations complémentaires
            </Typography>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <DateField
              id="birthdate"
              label="Date de naissance"
              format="DD/MM/YYYY"
              disableFuture
              value={form.data.birthdate ? dayjs(form.data.birthdate) : null}
              onChange={(value) => {
                //HACKY
                if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                  handleChange('birthdate', dayjs(value).format('YYYY-MM-DD'))
                }
              }}
              slots={{
                textField: (params) => (
                  <TextField
                    id="birthdate_textField"
                    {...params}
                    fullWidth
                    onBlur={() => {
                      if (!dayjs(form.data.birthdate).isValid()) {
                        setForm((prevState) => {
                          return {
                            ...prevState,
                            errors: {
                              ...prevState.errors,
                              birthdate: 'Veuillez entrer une date de naissance valide',
                            },
                          }
                        })
                      }
                    }}
                    error={Boolean(form.errors.birthdate)}
                    helperText={form.errors.birthdate}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item>
            <Typography variant="body1" sx={{ mt: 2, fontWeight: 500 }}>
              Veuillez vous assurer que les informations fournies correspondent exactement à celles figurant sur votre
              attestation.
            </Typography>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item sx={{ mt: 1 }} xs={12}>
              <TextField
                fullWidth
                label="SIRET (14 chiffres)"
                variant="outlined"
                id="invoice_company_siret"
                placeholder="123 456 789 12345"
                required
                value={form.data.invoice_company_siret
                  ?.replace(/\s/g, '')
                  .replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, '$1 $2 $3 $4')}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('invoice_company_siret', e?.target.value)
                }}
                error={Boolean(form.errors.invoice_company_siret)}
                helperText={form.errors.invoice_company_siret}
              />
            </Grid>
            <Grid item sx={{ mt: 1 }} xs={12}>
              <TextField
                fullWidth
                label="Adresse de facturation"
                variant="outlined"
                id="invoice_street"
                required
                value={form.data.invoice_street}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('invoice_street', e?.target.value)
                }}
                error={Boolean(form.errors.invoice_street)}
                helperText={form.errors.invoice_street}
              />
            </Grid>
            <Grid item sx={{ mt: 1 }} xs={12} md={6}>
              <TextField
                fullWidth
                label="Code postal"
                variant="outlined"
                id="invoice_postal_code"
                required
                value={form.data.invoice_postal_code}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('invoice_postal_code', e?.target.value)
                }}
                error={Boolean(form.errors.invoice_postal_code)}
                helperText={form.errors.invoice_postal_code}
              />
            </Grid>
            <Grid item sx={{ mt: 1 }} xs={12} md={6}>
              <TextField
                fullWidth
                label="Ville"
                variant="outlined"
                id="invoice_city"
                required
                value={form.data.invoice_city}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('invoice_city', e?.target.value)
                }}
                error={Boolean(form.errors.invoice_city)}
                helperText={form.errors.invoice_city}
              />
            </Grid>
          </Grid>

          <Grid item sx={{ mt: 2 }}>
            <FormControlLabel
              labelPlacement="end"
              sx={{ alignItems: 'flex-start', flexWrap: 'nowrap' }}
              checked={form.data.budget}
              control={
                <Checkbox
                  id="budget"
                  sx={{
                    marginTop: -1.3,
                  }}
                />
              }
              onChange={(_, checked) => {
                handleChange('budget', checked)
              }}
              label={
                <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" variant="body2">
                    Je dispose du budget suffisant auprès du FIF-PL
                  </Typography>
                  <Grid
                    item
                    sx={{
                      cursor: 'pointer',
                      p: 0,
                      ml: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                      setAnchorEl(e.currentTarget)
                    }}
                    onMouseLeave={() => {
                      setAnchorEl(null)
                    }}
                  >
                    <MdInfoOutline size="18" />
                  </Grid>
                </Grid>
              }
            />
            <Popover
              sx={{
                pointerEvents: 'none',
              }}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={() => {
                setAnchorEl(null)
              }}
              disableRestoreFocus
              anchorEl={anchorEl}
              open={open}
            >
              <Typography variant="caption" sx={{ p: 1 }}>
                {form.data.product_catalog?.funding_price ||
                  form.data.product_catalog?.listing_price ||
                  form.data.product?.listing_price ||
                  250}
                € sur votre enveloppe annuelle de {form.data.profession.fifpl_budget || 300}€
              </Typography>
            </Popover>
          </Grid>
        </Grid>
        <Modal
          open={videoModal}
          sx={{
            p: { xs: 1, md: 20 },
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClose={() => {
            setVideoModal(false)
          }}
        >
          <Grid item className="container">
            <iframe
              height="100%"
              width="100%"
              src="https://iframe.mediadelivery.net/embed/252149/294557d2-f9e9-427e-b9b8-5e4407f05075"
            />
          </Grid>
        </Modal>
      </Grid>
      <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          disabled={location.pathname.includes('/information')}
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate(-1)
          }}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          disabled={!isStepValid('financement', form)}
          sx={{
            height: {
              xs: 45,
            },
          }}
          endIcon={<MdNavigateNext />}
          onClick={submitFinancement}
        >
          Suivant
        </Button>
      </Grid>
    </>
  )
}

export default Fifpl
