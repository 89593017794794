import { Layout, Formtitle } from '../components'
import { Grid, TextField, Autocomplete, Button, Link, Checkbox, Typography, InputAdornment } from '@mui/material'
import { MdNavigateNext } from 'react-icons/md'
import { emailValidator, phoneNumberValidator } from '../utils/validators'
import { FormType } from '../utils/sharedTypes'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isStepValid } from '../utils/misc'
// import { exercices } from '../utils/constants'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { MdPhoneEnabled } from 'react-icons/md'
// import TagManager from 'react-gtm-module'

type InformationPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const Information = ({ form, setForm }: InformationPropsType) => {
  const navigate = useNavigate()
  const submitInfo = () => {
    client
      .patch(`/registration/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          if (
            form.data.funding_organism?.code === 'STRIPE' ||
            (form.data.registration_type == 'PreConfiguredRegistration' &&
              (form.data.funding_organism?.code === 'ALMA' || form.data.funding_organism?.code === 'BANK_TRANSFER'))
          ) {
            navigate({ pathname: './../recapitulatif/', search: location.search })
          } else {
            navigate({ pathname: './../financement/', search: location.search })
          }
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'email':
          if (!emailValidator(value as string)) {
            errors['email'] = 'Veuillez renseigner un e-mail valide'
          }
          break
        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        case 'first_name':
          if ((value as string).length === 0) {
            errors['first_name'] = 'Veuillez renseigner un prénom'
          }
          break
        case 'last_name':
          if ((value as string).length === 0) {
            errors['last_name'] = 'Veuillez renseigner un nom de famille'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    if (form.data.funding_organism?.code && form.funders.length !== 0) {
      setForm((prevState) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            funding_organism: {
              code: null,
            },
          },
          funders: [],
        }
      })
    }
  }, [])

  const hasProfessions =
    form.professions.length !== 0 && form.professions.length !== 1 && form.professions[0]?.code !== 'unknown'

  useEffect(() => {
    if (form.data.registration_type != 'PreConfiguredRegistration') {
      // Prevent profession update on `PreConfiguredRegistration`
      setForm((prevState) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            profession: {
              code: form.professions.length >= 2 ? '' : 'unknown',
            },
          },
        }
      })
    }
  }, [hasProfessions])

  return (
    <Layout
      form={form.data}
      steps={
        form.data.funding_organism?.code === 'STRIPE' ? form.steps.filter((s) => s.url !== 'financement') : form.steps
      }
    >
      <Formtitle
        title="Informations personnelles"
        subtitle={
          form.data.registration_type == 'PreConfiguredRegistration'
            ? 'Veuillez confirmez les informations ci-dessous'
            : 'Remplissez le formulaire ci-dessous pour poursuivre votre inscription'
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submitInfo()
        }}
      >
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={
                (form.data.funding_organism?.code === 'FIFPL' && Boolean(form.data.personal_funding)) ||
                (form.data.funding_organism?.code === 'DPC' &&
                  form.data.registration_type === 'PreConfiguredRegistration')
              }
              fullWidth
              label="Prénom"
              variant="outlined"
              id="first_name"
              required
              value={form.data.first_name}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('first_name', e?.target.value)
              }}
              error={Boolean(form.errors.first_name)}
              helperText={form.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={
                (form.data.funding_organism?.code === 'FIFPL' && Boolean(form.data.personal_funding)) ||
                (form.data.funding_organism?.code === 'DPC' &&
                  form.data.registration_type === 'PreConfiguredRegistration')
              }
              fullWidth
              label="Nom"
              id="last_name"
              variant="outlined"
              required
              value={form.data.last_name}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('last_name', e?.target.value)
              }}
              error={Boolean(form.errors.last_name)}
              helperText={form.errors.last_name}
            />
          </Grid>
          {hasProfessions && (
            <Grid item xs={12}>
              <Autocomplete
                disabled={
                  (form.data.funding_organism?.code === 'FIFPL' && Boolean(form.data.personal_funding)) ||
                  (form.data.funding_organism?.code === 'DPC' &&
                    form.data.registration_type === 'PreConfiguredRegistration')
                }
                disableClearable
                disablePortal
                id="profession"
                value={form.professions.filter((p) => p?.code === form.data.profession.code)[0] || null}
                getOptionLabel={(option) => option?.name}
                onChange={(_, value) => {
                  if (value) {
                    handleChange('profession', { code: value.code })
                  }
                }}
                options={form.professions}
                renderInput={(params) => <TextField {...params} required label="Profession" />}
              />
            </Grid>
          )}
          {/* {form.data.theme === 'sante' && (
          <Grid item xs={12} md={hasProfessions ? 6 : 12}>
            <Autocomplete
              disablePortal
              disableClearable
              value={exercices.filter((p) => p.code === form.data.exercice)[0] || null}
              id="exercice"
              onChange={(_, value) => {
                if (value) {
                  handleChange('exercice', value.code)
                }
              }}
              options={exercices}
              renderInput={(params) => <TextField {...params} required label="Mode d'exercice" />}
            />
          </Grid>
        )} */}
          <Grid item xs={12}>
            <TextField
              disabled={
                (form.data.funding_organism?.code === 'FIFPL' && Boolean(form.data.personal_funding)) ||
                (form.data.funding_organism?.code === 'DPC' &&
                  form.data.registration_type === 'PreConfiguredRegistration')
              }
              fullWidth
              type="email"
              label="Adresse e-mail"
              id="email"
              variant="outlined"
              required
              value={form.data.email}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('email', e?.target.value)
              }}
              error={Boolean(form.errors.email)}
              helperText={form.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={
                (form.data.funding_organism?.code === 'FIFPL' && Boolean(form.data.personal_funding)) ||
                (form.data.funding_organism?.code === 'DPC' &&
                  form.data.registration_type === 'PreConfiguredRegistration')
              }
              type="tel"
              fullWidth
              id="mobile_phone"
              label="N° de mobile"
              variant="outlined"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MdPhoneEnabled size="20" />
                  </InputAdornment>
                ),
              }}
              value={form.data.mobile_phone}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('mobile_phone', e?.target.value)
              }}
              error={Boolean(form.errors.mobile_phone)}
              helperText={form.errors.mobile_phone}
            />
          </Grid>
          <Grid item container alignItems="center" wrap="nowrap">
            <Checkbox
              id="rgpd"
              sx={{ mt: { xs: 0, sm: 0, md: 0 } }}
              checked={form.data.rgpd}
              onClick={() => {
                handleChange('rgpd', !form.data.rgpd)
              }}
            />
            <Typography variant="body2">
              J'accepte la{' '}
              <Link href="https://walter-learning.com/privacy" target="_blank">
                Politique de Confidentialité des Données
              </Link>
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={!isStepValid('information', form)}
              sx={{
                height: {
                  xs: 45,
                },
              }}
              endIcon={<MdNavigateNext />}
              // onClick={() => {
              //   submitInfo()
              //   // TagManager.dataLayer({
              //   //   dataLayer: {
              //   //     event: 'formSubmit.Success',
              //   //   },
              //   // })
              // }}
            >
              Suivant
            </Button>
          </Grid>
        </Grid>
      </form>
    </Layout>
  )
}

export default Information
