import Bugsnag from '@bugsnag/js'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  ApprenticeshipConfirmation,
  ApprenticeshipEnterprise,
  ApprenticeshipInformation,
  ApprenticeshipProfile,
  ApprenticeshipSituation,
} from '../pages'
import { client } from '../utils/client'
import { ApprenticeshipFormDefaultState } from '../utils/constants'
import {
  ApprenticeshipAPIResponse,
  ApprenticeshipDiplomaType,
  ApprenticeshipFormData,
  ApprenticeshipFormType,
  ApprenticeshipLastYearOutcomeType,
  ParamsType,
} from '../utils/sharedTypes'

export default function Apprenticeship() {
  const [params, setParams] = useState<ParamsType>({})
  const [form, setForm] = useState<ApprenticeshipFormType>(ApprenticeshipFormDefaultState)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const searchObj = window.location.search ? Object.fromEntries(new URLSearchParams(window.location.search)) : {}
    setParams(searchObj)
    setForm((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          utm_campaign: searchObj.utm_campaign || null,
          utm_source: searchObj.utm_source || null,
          utm_medium: searchObj.utm_medium || null,
          utm_content: searchObj.utm_content || null,
          utm_term: searchObj.utm_term || null,
          adset_id: searchObj.adset_id || null,
          entrypoint_url: decodeURIComponent(searchObj.entrypoint_url) || null,
        },
      }
    })
  }, [])

  useEffect(() => {
    if (params.global_id) {
      setIsLoading(true)
      client
        .get(`/apprenticeship/public/${params.global_id}/`)
        .then((resp) => {
          const data = resp.data as ApprenticeshipAPIResponse
          const formData = {
            global_id: data.global_id,
            first_name: data.first_name,
            last_name: data.last_name,
            mobile_phone: data.mobile_phone,
            email: data.email,
            last_diploma_title: data.last_diploma_title,
            last_diploma_prepared: data.last_diploma_prepared ? data.last_diploma_prepared.toString() : '',
            last_diploma_name: data.last_diploma ? data.last_diploma.toString() : '',
            nationality: data.nationality,
            nir: data.nir,
            street: data.street,
            postal_code: data.postal_code,
            city: data.city,
            country: data.country,
            gender: data.gender,
            birth_date: data.birth_date,
            birth_department: data.birth_department,
            birth_city: data.birth_city,
            is_born_abroad: data.is_born_abroad,
            curriculum_vitae: data.curiculum_vitae,
            has_apprenticeship_company: data.has_apprenticeship_company,
            apprenticeship_company_name: data.apprenticeship_company_name,
            apprenticeship_company_street: data.apprenticeship_company_street,
            apprenticeship_company_postal_code: data.apprenticeship_company_postal_code,
            apprenticeship_company_city: data.apprenticeship_company_city,
            apprenticeship_company_country: data.apprenticeship_company_country,
            apprenticeship_company_responsable_first_name: data.apprenticeship_company_responsable_first_name,
            apprenticeship_company_responsable_last_name: data.apprenticeship_company_responsable_last_name,
            apprenticeship_company_responsable_email: data.apprenticeship_company_responsable_email,
            apprenticeship_company_responsable_mobile_phone: data.apprenticeship_company_responsable_mobile_phone,
            has_legal_representative: data.has_legal_representative,
            legal_representative_email: data.legal_representative_email,
            legal_representative_first_name: data.legal_representative_first_name,
            legal_representative_last_name: data.legal_representative_last_name,
            legal_representative_mobile_phone: data.legal_representative_mobile_phone,
            legal_representative_postal_code: data.legal_representative_postal_code,
            legal_representative_street: data.legal_representative_street,
            legal_representative_city: data.legal_representative_city,
            legal_representative_country: data.legal_representative_country,
            want_to_create_enterprise: data.want_to_create_enterprise,
            has_rqth_rights: data.has_rqth_rights,
            aid_under_20: data.aid_under_20,
            boe_beneficiary: data.boe_beneficiary,
            last_student_year_outcome: data.last_student_year_outcome ? data.last_student_year_outcome.toString() : '',
            is_disabled_worker: data.is_disabled_worker,
            is_high_level_athlete: data.is_high_level_athlete,
            want_to_move_out: data.want_to_move_out,
            move_out_city: data.move_out_city,
            move_out_postal_code: data.move_out_postal_code,
            situation_before_contract: data.situation_before_contract,
            social_regime: data.social_regime,
            former_salary: data.former_salary || null,
            former_weekly_work_duration: data.former_weekly_work_duration || null,
            apprenticeship_type: data.apprenticeship_type,
            is_former_apprenticeship: data.is_former_apprenticeship,
            product: data.sf_product,
            // CONTRAT PRO
            is_registered_to_pole_emploi: data.is_registered_to_pole_emploi || false,
            pole_emploi_registration_number: data.pole_emploi_registration_number,
            pole_emploi_duration: data.pole_emploi_duration,
            social_minimum_type: data.social_minimum_type,
          } as ApprenticeshipFormData

          client
            .get(`/apprenticeship/public/picklists/?contract_type=${data.apprenticeship_type}`)
            .then((resp) => {
              const data = resp.data as {
                diplomas: ApprenticeshipDiplomaType[]
                last_student_year_outcomes: ApprenticeshipLastYearOutcomeType[]
              }

              setForm((prevState) => {
                return {
                  ...prevState,
                  diplomas: data.diplomas,
                  last_student_year_outcomes: data.last_student_year_outcomes,
                }
              })
            })
            .catch((err: Error) => {
              console.error(err)
              Bugsnag.notify(err)
            })

          setForm((prevState) => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                ...formData,
              },
            }
          })
        })
        .catch((err: Error) => {
          console.error(err)
          Bugsnag.notify(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [params.global_id])

  useEffect(() => {
    if (!isLoading && !form.data.has_apprenticeship_company) {
      setForm((prevState) => {
        return {
          ...prevState,
          steps: prevState.steps.filter((step) => step.url !== 'enterprise'),
        }
      })
    }
  }, [isLoading, form.data.has_apprenticeship_company])

  return (
    <Routes>
      <Route path="/information" element={<ApprenticeshipInformation form={form} setForm={setForm} />} />
      <Route path="/entreprise" element={<ApprenticeshipEnterprise form={form} setForm={setForm} />} />
      <Route path="/profile" element={<ApprenticeshipProfile form={form} setForm={setForm} />} />
      <Route path="/situation" element={<ApprenticeshipSituation form={form} setForm={setForm} />} />
      <Route path="/confirmation" element={<ApprenticeshipConfirmation />} />
      <Route path="*" element={<Navigate to="information" replace />} />
    </Routes>
  )
}
