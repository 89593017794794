import { useEffect, useRef, useState } from 'react'
import { Layout, Formtitle } from '../components'
import { FormType, NormalFormData, ProductType } from '../utils/sharedTypes'
import { Grid, Typography, Button, Box, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { FormDefaultState } from '../utils/constants'
import DPCTuto from '../components/DPCTuto'

type ValidationPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

const PreInscription = ({ form, setForm }: ValidationPropsType) => {
  console.log('Validation', form)
  const products = form.data.product ? [form.data.product] : form.data.products
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(products[0])
  const [autoSelect, setAutoSelect] = useState(true)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        steps: FormDefaultState.steps
          .slice(0, 2)
          .concat([
            {
              label: 'Pré-inscription',
              url: 'pre-inscription',
            },
          ])
          .concat(FormDefaultState.steps.slice(2)),
      }
    })
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (form.data.global_id) {
      timer.current = setInterval(() => {
        getRegistration()
      }, 10000)
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [form.data.global_id, timer.current])

  const getRegistration = () => {
    if (timer.current) {
      clearInterval(timer.current)
    }

    client
      .get(`/registration/${form.data.global_id}/`)
      .then((resp) => {
        const data = resp.data as NormalFormData

        if (
          JSON.stringify(data.funding_organism_registrations) !==
          JSON.stringify(form.data.funding_organism_registrations)
        ) {
          setAutoSelect(true)
        }

        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              ...data,
            },
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const submitForm = () => {
    client
      .patch(`/registration/${form.data.global_id}/submit/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          if (form.data.custom_processes_validation && form.data.pre_registration_custom_processes.length !== 0) {
            navigate({ pathname: './../signature', search: location.search })
          } else {
            if (form.data.registration_type == 'SelfRegistration' && form.data.funding_organism?.code == 'DPC') {
              navigate({ pathname: './../recommandation', search: location.search })
            } else {
              navigate({ pathname: './../recapitulatif', search: location.search })
            }
          }
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  useEffect(() => {
    if (autoSelect)
      setSelectedProduct(
        products.find(
          (product) =>
            !form.data.funding_organism_registrations.find((r) => r.product.product_code === product.product_code) ||
            form.data.funding_organism_registrations.find((r) => r.product.product_code === product.product_code)
              ?.cancelled,
        ) || null,
      )
  }, [JSON.stringify(form.data.funding_organism_registrations), autoSelect])

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title={form.data.registration_type == 'SelfRegistration' ? 'Inscription DPC' : 'Pré-inscription'}
        subtitle={
          form.data.pre_registration
            ? 'Vous avez été automatiquement pré-inscrits'
            : form.data.funding_organism_registration_validated
            ? products.length > 1
              ? `Vos ${
                  form.data.registration_type == 'SelfRegistration' ? 'inscriptions DPC' : 'pré-inscriptions'
                } ont été validées`
              : `Votre ${
                  form.data.registration_type == 'SelfRegistration' ? 'inscription DPC' : 'pré-inscription'
                } est validée`
            : products.length > 1
            ? `C'est le moment de procéder à vos ${
                form.data.registration_type == 'SelfRegistration' ? 'inscriptions DPC' : 'pré-inscriptions DPC'
              }`
            : `C'est le moment de procéder à votre ${
                form.data.registration_type == 'SelfRegistration' ? 'inscription DPC' : 'pré-inscription DPC'
              }`
        }
      />

      <Grid item container xs={12} direction="column">
        <Grid container xs={12} justifyContent="end">
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              setAutoSelect(true)
              getRegistration()
            }}
          >
            Rafraichir
          </Button>
        </Grid>

        <Grid
          container
          direction="row"
          flexWrap="nowrap"
          gap={0.5}
          mt={2}
          sx={{
            width: '100%',
            maxWidth: '100%',
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {products.map((product) => {
            const funding_organism_registration = form.data.funding_organism_registrations.find(
              (r) => r.product.product_code === product.product_code,
            )

            const selected = selectedProduct?.product_code === product.product_code

            return (
              <Grid
                item
                container
                sx={{
                  position: 'relative',
                  width: '100%',
                  minWidth: 200,
                  maxHeight: 200,
                  maxWidth: 200,
                  borderRadius: '4px',
                  cursor: 'pointer',
                  border: selected ? '5px solid #A1A1A1' : '5px solid white',
                }}
                key={product.product_code}
                onClick={() => {
                  setSelectedProduct(product)
                  setAutoSelect(false)
                }}
              >
                <Grid
                  item
                  sx={{
                    position: 'absolute',
                    top: 7.5,
                    left: 7.5,
                    paddingTop: '1.5px',
                    paddingBottom: '2.5px',
                    paddingX: '5px',
                    borderRadius: '4px',
                    zIndex: 2,
                    background:
                      !form.data.pre_registration &&
                      (!funding_organism_registration || funding_organism_registration?.cancelled)
                        ? 'black'
                        : '#03835c',
                  }}
                >
                  <Typography fontSize="13px" color="white" fontWeight="500">
                    {!form.data.pre_registration &&
                    (!funding_organism_registration || funding_organism_registration?.cancelled)
                      ? 'En attente'
                      : !form.data.pre_registration
                      ? 'Validé'
                      : 'Pré-inscrit'}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #252831 100%)',
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                  }}
                  borderRadius={selected ? '1px' : '4px'}
                />

                <Box
                  component="img"
                  src={`https://walter-production-resources.s3.eu-west-3.amazonaws.com/formations/${product.product_code.toLowerCase()}/img2/card_w255_h410.jpeg`}
                  height="100%"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'relative',
                    top: 0,
                  }}
                  borderRadius={selected ? '1px' : '4px'}
                />
                <Box
                  component="img"
                  src={`https://walter-production-resources.s3.eu-west-3.amazonaws.com/formations/${product.product_code.toLowerCase()}/img2/logotype.svg`}
                  sx={{
                    position: 'absolute',
                    bottom: 25,
                    left: 0,
                    width: '100%',
                    height: 'auto',
                    paddingX: 2,
                    zIndex: 2,
                  }}
                />
              </Grid>
            )
          })}
        </Grid>

        {!form.data.pre_registration && selectedProduct && (
          <DPCTuto form={form} setForm={setForm} dpc_action_number={selectedProduct.dpc_action_number} header={false} />
        )}

        {form.data.funding_organism_registration_validated && (
          <Grid my={2}>
            <Alert severity="success">
              {form.data.registration_type == 'SelfRegistration' ? (
                <>
                  Nous avons bien reçu de la part de l'ANDPC la confirmation de prise en charge pour{' '}
                  {products.length > 1 ? `vos ${products.length} inscriptions` : 'votre inscription'}, il ne reste plus
                  qu'à cliquer sur le bouton en bas à droite pour finaliser votre inscription.
                </>
              ) : (
                <>
                  Nous avons bien reçu{' '}
                  {products.length > 1 ? `vos ${products.length} pré-inscriptions` : 'votre pré-inscription'}, vous
                  pouvez maintenant passer à l'étape suivante pour finaliser votre inscription.
                </>
              )}
            </Alert>
          </Grid>
        )}

        <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            variant="outlined"
            disabled={location.pathname.includes('/financement')}
            sx={{
              height: {
                xs: 45,
              },
            }}
            startIcon={<MdNavigateBefore />}
            onClick={() => {
              navigate({ pathname: './../financement/', search: location.search })
              setForm((prevState) => {
                return {
                  ...prevState,
                  data: {
                    ...prevState.data,
                    dpc_submitted: false,
                  },
                }
              })
            }}
          >
            Retour
          </Button>

          <Button
            variant="contained"
            onClick={submitForm}
            disabled={!form.data.funding_organism_registration_validated && !form.data.pre_registration}
            endIcon={
              form.data.registration_type == 'SelfRegistration' &&
              form.data.funding_organism?.code == 'DPC' &&
              form.data.pre_registration_custom_processes.length === 0 ? undefined : (
                <MdNavigateNext />
              )
            }
          >
            {form.data.registration_type == 'SelfRegistration' &&
            form.data.funding_organism?.code == 'DPC' &&
            form.data.pre_registration_custom_processes.length === 0
              ? 'Finaliser mon inscription'
              : 'Suivant'}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default PreInscription
